import React from "react";
import { graphql } from "gatsby";
import { useTranslation, Link } from "gatsby-plugin-react-i18next";

import Layout from "../../components/Layout";
import MetaComponents from "../../components/seo/MetaComponents";
import ArticleStructuredData from "../../components/seo/ArticleStructuredData";
import Article from "../../components/Article";
import ArticleHeader from "../../components/ArticleHeader";
import MortgageCalculator from "../../components/MortgageCalculator";
import { urls, extendUrlWithSourceVersion } from "../../constants/urls";
import BreadcrumbList from "../../components/seo/BreadcrumbList";

const FinanzierungOhneEigenkapital = () => {
    const { t } = useTranslation();

    return (
        <Layout>
            <MetaComponents title={t("page.meta.title")} description={t("page.meta.description")} />
            <ArticleHeader h1Title="Die Finanzierung ohne Eigenkapital" showCalc={false} />
            <Article>
                <p>
                    Unter bestimmten Umständen ist eine{" "}
                    <Link to="/artikel/immobilienfinanzierung/" target="_blank" rel="noreferrer noopener">
                        Immobilienfinanzierung
                    </Link>{" "}
                    ohne Eigenkapital durchaus möglich. Allerdings eignet sich eine solche Finanzierung nicht für
                    jede*n. In diesem Artikel erfährst du, ob sie für dich infrage kommt und worauf du dabei achten
                    solltest.
                </p>
                <hr />

                <h2>Welche Möglichkeiten es gibt</h2>
                <p>
                    Bei einer{" "}
                    <Link to="/artikel/immobilienfinanzierung/" target="_blank" rel="noreferrer noopener">
                        Immobilienfinanzierung
                    </Link>{" "}
                    ohne Eigenkapital wird der volle Kaufpreis der Immobilie mit Hilfe eines Kredits finanziert, weshalb
                    man manchmal auch von einer Vollfinanzierung spricht. Aber es gibt dabei zwei unterschiedliche
                    Varianten:
                </p>
                <ul>
                    <li>
                        Die 100%-Finanzierung: hier wird „nur“ der volle Kaufpreis deiner Immobilie durch Kredite
                        finanziert. Die Kaufnebenkosten werden von dir selbst getragen
                    </li>
                    <li>
                        Die 110%-Finanzierung oder Vollfinanzierung: Bei der Vollfinanzierung wird, wie der Name bereits
                        sagt, der gesamte Kaufpreis der Immobilie sowie auch alle anfallenden Kaufnebenkosten von einem
                        Kredit abgedeckt. In diesem Fall bringst du überhaupt kein Eigenkapital mit ein.
                    </li>
                </ul>
                <p>
                    Die Vollfinanzierung bringt viele Nachteile mit sich, weil sie länger dauert und langfristig viel
                    mehr kostet. Deshalb empfehlen wir meistens, zumindest die Nebenkosten selbst zu finanzieren.
                </p>
                <p>
                    Bei jeder{" "}
                    <Link to="/artikel/immobilienfinanzierung/" target="_blank" rel="noreferrer noopener">
                        Immobilienfinanzierung
                    </Link>{" "}
                    gilt: Je mehr Eigenkapital du aufbringen kannst, desto geringer fallen die Zinsen an, die dich
                    erwarten. Wenn du kein Eigenkapital hast, verlangen Banken für das höhere Risiko auch höhere
                    Zinsaufschläge, um sich selbst abzusichern. Dadurch fällt deine Monatsrate automatisch um einiges
                    höher aus. Das bedeutet, dass du ein recht hohes Einkommen haben solltest, um die hohen Monatsraten
                    bezahlen zu können. Eine überdurchschnittlich gute Kreditwürdigkeit (Bonität) ist auf jeden Fall die
                    wichtigste Voraussetzung für eine Finanzierung ohne Eigenkapital.
                </p>
                <p>
                    <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                        Jetzt zum Kreditvergleich!
                    </a>
                </p>
                <hr />

                <h2>Wann ist die Finanzierung ohne Eigenkapital sinnvoll?</h2>
                <p>
                    Die Vollfinanzierung von Immobilien ist ziemlich teuer und nur in sehr wenigen Fällen
                    empfehlenswert. Sie kommt in Zeiten niedriger Zinsen, wie jetzt gerade, und bei einem sicheren und
                    relativ hohen Einkommen allerdings durchaus in Frage. Bevor du dich für eine solche Finanzierung
                    entscheidest, solltest du dich jedoch gut beraten lassen und alle Vor- und Nachteile sowie Risiken
                    abwägen.
                </p>
                <p>
                    <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                        Jetzt Beratung anfordern!
                    </a>
                </p>
                <p>
                    Wenn du über eine überdurchschnittliche{" "}
                    <Link to="/artikel/bonitaet/" target="_blank" rel="noreferrer noopener">
                        Bonität
                    </Link>{" "}
                    verfügst und ein hohes Einkommen hast, kann eine Vollfinanzierung also für dich sinnvoll sein. Nur
                    so kannst du den bevorstehenden Nachteilen und Risiken überhaupt entgegentreten. Wenn du
                    selbstständig bist, oder ein freies Angestelltenverhältnis hast, stehen deine Chancen, eine
                    Vollfinanzierung zu erhalten und aufrechtzuerhalten, wesentlich schlechter. Dein Einkommen ist nicht
                    sicher und unterliegt in Zukunft möglicherweise Schwankungen, weshalb die Risiken einfach zu hoch
                    sind.
                </p>
                <p>
                    Eine Finanzierung ohne Eigenkapital ist für Selbstständige deshalb sehr schwer zu bekommen. Die
                    meisten Bänke treffen solche Entscheidungen je nach Einzelfall. Ärzt*innen und Rechtsanwält*innen
                    haben es dabei meist leichter als Gewerbetreibende. Du kannst deine Chance auf einen solchen Kredit
                    jedoch steigern, indem du einen zweiten Kreditnehmer mit regelmäßigen und sicheren Einkommen
                    heranziehst, der für die Bank dann das Risiko vermindert.
                </p>
                <hr />

                <h2>So erhöhst du deine Chancen auf eine Finanzierung ohne Eigenkapital</h2>
                <p>
                    Noch eher steigerst du deine Chancen, wenn du zumindest das Kapital für die Kaufnebenkosten selbst
                    aufbringen kannst. Die Nebenkosten, wie die Grunderwerbsteuer oder die Maklergebühr sind Geld, das
                    nach dem Kauf nicht mehr bleibt, es gibt für sie keinen materiellen Gegenwert mehr. Das erhöht die
                    Finanzierungsrisiken, welches Banken möglichst gering halten möchten. Das hohe Risiko einer
                    110%-Finanzierung gleicht die Bank dann mit Zinsaufschlägen aus, weshalb diese Finanzierung um
                    einiges teurer ausfällt und sich langfristig vielleicht gar nicht auszahlt.
                </p>
                <p>
                    Darüber hinaus ist der Zustand deiner Immobilie entscheidend. Wenn das Objekt gut erhalten ist und
                    in einer beliebten Lage steht, steigert es die Chance, eine Finanzierung zu erhalten. Vor allem,
                    wenn man abschätzen kann, dass der Wert der Immobilie steigt, stimmt die Bank eher zu, deine
                    Immobilie ohne Eigenkapital zu finanzieren.
                </p>
                <p>
                    <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                        Jetzt zum Erstgespräch!
                    </a>
                </p>
                <hr />

                <h2>Fazit</h2>
                <p>
                    Wenn du also einen befristeten Job hast, Selbstständig oder frei angestellt bist, oder dich in der
                    Probezeit befindest, solltest du eine{" "}
                    <Link to="/artikel/immobilienfinanzierung/" target="_blank" rel="noreferrer noopener">
                        Immobilienfinanzierung
                    </Link>{" "}
                    ohne Eigenkapital lieber nicht in Anspruch nehmen. Am Ende stehst du möglicherweise ohne Job vor
                    einem riesigen Schuldenberg. Die Vollfinanzierung ist auch zu riskant, wenn dein Gehalt durch eine
                    Schwangerschaft, einen Unfall oder Krankheit sinkt oder völlig wegfällt. In diesen Fällen wird eine
                    Finanzierung ohne Eigenkapital meist gar nicht gewährt, gleichzeitig sind das Dinge, die
                    unvorhersehbar sein können und die du deshalb immer mitbedenken solltest. Ein sicherer Arbeitsplatz,
                    ein hohes Einkommen, das Eigenkapital zur Deckung der Nebenkosten und niedrige Zinsen machen die
                    Finanzierung ohne Eigenkapital denkbar und je nach Höhe des Gehalts wahrscheinlicher.
                </p>
                <p>
                    Eine Finanzierung ohne Eigenkapital ist also möglich. Ob sie für dich infrage kommt und ob du die
                    Voraussetzungen dafür wirklich erfüllst, solltest du dir vorher allerdings gut überlegen. Die
                    Risiken sind nicht zu unterschätzen und die Zukunft ist unvorhersehbar. Lass dich vor einer solchen
                    Entscheidung immer gut beraten.
                </p>
            </Article>
            <MortgageCalculator noTopMargin defaultTerm={35} defaultMortgage={50000} />

            <BreadcrumbList page={"finanzierungOhneEigenkapital"}></BreadcrumbList>
            <ArticleStructuredData
                page={"finanzierungOhneEigenkapital"}
                heading={"Die Finanzierung ohne Eigenkapital"}
            />
        </Layout>
    );
};

export default FinanzierungOhneEigenkapital;

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(
            filter: {
                ns: { in: ["header", "footer", "page.artikel.finanzierung-ohne-eigenkapital", "mortgageCalc"] }
                language: { eq: $language }
            }
        ) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;
